@import "../../../../../../styles/variables.scss";

.property-information {
    padding: 1rem;
    padding-top: 2rem;

    &__card {
        padding: 1rem;

        p {
            margin-top: 0;
            margin-bottom: .8rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .unspecified {
            color: $gray;
            font-style: italic;
            font-size: .7rem;
        }

    }

    &__edit {
        padding-top: 1rem;
        border-top: solid 1px $light-gray;
        text-align: right;

        &.double {
            display: flex;
            justify-content: space-between;
        }
    }

    .form-group {
        margin-bottom: 1rem;
    }

    &__form {
        display: flex;
        align-items: center;

        label {
            width: 20%;
        }

        .form-group__item,
        .form-group__item-management-date{
            width: 80%;
            margin-left: .5rem;
        }
    }

    .form-group__msg {
        justify-content: flex-end;
    }
}
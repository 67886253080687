@import "../../../../styles/variables.scss";

.agency-companies {
    padding: 1rem;

    &__wrapper {
        background: white;
        border: solid 1px #eeedec;
        margin: 1rem 0;
        padding-bottom: 1rem;

        &-item {
            padding: 0.625rem 1.25rem;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        width: 100%;

        h4 {
            margin: 0;
            text-align: left;
        }

        button.btn {
            background-color: white;
        }
    }

    p {
        text-align: center;
    }

    .paginator {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }
}
$orange: #FB9800;
$light-orange: rgba(247, 132, 1, 0.26);
$green: #45C34B;
$light-green: #DEF5DE;
$red: #F52222;
$light-red: #FEEBEB;
$light-blue: #F2F7FC;
$dark-blue: #1460aa;
$blue: #CAD8E6;
$light-gray: #eeedec;
$background-gray: #FAF9F8;
$gray: #7C8A8E;
$dark-gray: #616972;

@import "../../../../styles/variables";

.property-filter-component {
	padding: 10px 0;
	background-color: white;
	width: 500px;
	font-size: 1rem;
	margin-top: 10px;
	max-height: 600px;
	// overflow: auto;
	-webkit-box-shadow: 0 10px 10px 0 rgba(60, 64, 67, 0.3), 0 5px 30px 10px rgba(60, 64, 67, 0.15);
	box-shadow: 0 10px 10px 0 rgba(60, 64, 67, 0.3), 0 5px 30px 10px rgba(60, 64, 67, 0.15);

	.filter-item {
		display: flex;
		align-items: center;
		color: $dark-gray;
		margin: 5px 0;

		input {
			padding: 0.3rem;
		}

		.select__control {
			height: 35px;
			min-height: 35px !important;
		}
	}

	.buttons {
		padding-top: 10px;
		border-top: solid 1px $light-gray;
		display: flex;
		align-items: center;
		justify-content: space-between;

		button {
			text-transform: uppercase;
		}
	}
}

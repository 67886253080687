@import "../../../../../../styles/variables";

.property-title-component {
	background-color: white;
	border: solid 1px $light-gray;
	border-left: none;
	height: 3.75rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 1.5rem;
	
	&__title {
		margin: 0;
		font-size: 1.625rem;
		font-weight: 500;
		color: $dark-gray;
		width: 65%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&__icon {
		cursor: pointer;
	}
}

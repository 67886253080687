@import "../../../../../../styles/variables";

.property-supply-info-component {
	background-color: white;

	.info-item {
		display: flex;
		align-items: center;
		color: $dark-gray;
		margin: 5px 0;

		.icon {
			fill: $dark-gray;
			margin-right: 10px;
		}
	}

	.incidences {
		margin: 16px 0;
		font-size: 0.9rem;

		.title {
			color: $dark-blue;
			font-weight: bold;
			margin-bottom: 24px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.btn {
				text-transform: uppercase;
			}
		}

		.open-incidences {
			border: solid 1px $orange;
			color: $orange;
			background-color: $light-orange;
			text-align: center;
			padding: 8px;
			margin: 1em 0;
		}

		.no-open-incidences {
			border: solid 1px $dark-blue;
			color: $dark-blue;
			background-color: $light-blue;
			text-align: center;
			padding: 8px;
			margin: 1em 0;
		}

		.actions {
			max-height: 300px;
			overflow-y: auto;

			.action-item {
				display: flex;
				align-items: center;
				margin-top: 10px;

				> div {
					width: calc(100% - 70px);

					p {
						color: $dark-blue;
						margin: 5px 0;
						max-width: 100%;
					}

					span {
						max-width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						display: block;
					}
				}

				button {
					width: 60px;
					margin-left: 10px;
				}
			}
		}
	}

	.bottom {
		padding: 15px;
		border-top: solid 1px $light-gray;
		display: flex;
		justify-content: space-between;

		button {
			text-transform: uppercase;
		}
	}
}

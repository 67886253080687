@import "../../../../styles/variables";

.properties-list-component {
	min-height: 100%;

	&__sticky {
		position: sticky;
		top: 0;
	}
	
	.search-bar {
		height: 3.75rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		border: solid 1px $light-gray;
		border-bottom: none;
		position: relative;
		background-color: white;

		form {
			display: flex;

			button {
				background-color: $dark-blue;
				border: none;
				border-bottom-left-radius: 2px;
				border-top-left-radius: 2px;
				width: 42px;
				height: 42px;

				svg {
					fill: white;
				}
			}

			input {
				margin-right: 5px;
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
				border-left: none;
				width: calc(100% - 42px);
			}
		}

		svg {
			fill: $dark-gray;

			&.filters-hidden {
				-webkit-transform: rotate(90deg);
				-moz-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				-o-transform: rotate(90deg);
				transform: rotate(90deg);
			}
		}
	}

	.export-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 20px;
		border: solid 1px $light-gray;
		color: $dark-gray;
		background: white;

		svg {
			fill: $dark-gray;
			margin-left: 5px;
		}
	}

	.selected-properties {
		background-color: $light-blue;
		color: $dark-blue;
		font-size: 0.8rem;
		border: solid 1px $dark-blue;
		padding: 10px;
		text-align: center;
	}

	.property-item {
		display: flex;
		align-items: center;
		padding: 16px 20px;
		border: solid 1px $light-gray;
		border-bottom: none;
		cursor: pointer;

		&:hover {
			background-color: $light-blue;
		}

		.property-info {
			color: $gray;
			width: calc(100% - 26px);
			padding-left: 10px;
		}

		&.active {
			background-color: $background-gray;
			border-right: none;
			border-left: solid 6px $dark-blue;
			padding-left: 14px;

			.property-info {
				color: $dark-blue;
			}
		}

		&:last-child {
			border-bottom: solid 1px $light-gray;
		}
	}

	.see-more {
		background-color: $light-blue;
		color: $dark-gray;
		font-weight: bold;
		padding: 16px;
		text-align: center;
		cursor: pointer;
		border: solid 1px $light-gray;
	}
}

@import "../../../../styles/variables";

.agency-users-component {
	padding: 16px;

	.users-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;

		h4 {
			color: $dark-blue;
			margin: 0;
		}

		button {
			background-color: white;
		}
	}

	table {
		width: 100%;

		tr {
			background-color: white;
			border: solid 1px $light-gray;
			color: $gray;

			th, td {
				padding: 10px 20px;
				font-weight: normal;
				text-align: left;
				font-size: 0.9rem;

				&:nth-child(1) {
					width: 30%;
				}

				&:nth-child(2) {
					width: 40%;
				}

				&:nth-child(3) {
					width: 15%;
				}

				&:nth-child(4) {
					width: 15%;
				}
			}

			.email {
				color: $dark-blue;
			}

			.role {
				padding: 5px 10px;
				border-radius: 4px;
				display: block;
				text-align: center;

				&.admin {
					background-color: $light-orange;
				}

				&.user {
					background-color: $light-blue;
				}
			}

			.icons {
				text-align: right;

				svg {
					fill: $dark-gray;
					margin-left: 5px;
				}
			}
		}
	}

	p {
		text-align: center;
	}
}

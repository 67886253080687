.overlay-component {
  position: absolute;

  &:after {
    content: '';
    position: absolute;
    top: -10px;
    right: 50px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: white;
    border-top: 0;
  }
}

@import '../../../styles/variables';

.drag-drop-component {
    background-color: $light-blue;
    border: 1px solid $blue;
    border-radius: 0.125rem;
    height: 118px;
    width: 100%;
    position: relative;

    input {
        opacity: 0;
        overflow: hidden;
        position: absolute;
        height: 118px;
        width: 100%;
        cursor: pointer;
    }

    &-content {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;

        &--wrapper {
            text-align: center;
            margin: 1rem 0;

            .icon {
                fill: $blue;
            }
        }

        &--value {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 1rem;

            .close__btn {
                cursor: pointer;
            }
        }
	}

    .height__0 {
        height: 0;
        padding: 0;
    }
}

.height__addon {
    height: 40px;
}

.import-log-detail-component {
    & .import-log-error {
        color: red;
    }
    & .card {
        margin-bottom: 1rem;
        & button {
            cursor: pointer;   
        }

        & .card-title {
            display: flex;
            justify-content: space-between;
        }
    }
    .no-imports {
        text-align: center;
        padding-top: 20px;
    }
    .import-result-status {
        & svg {
            margin-right: 10px;
        }
    }
}
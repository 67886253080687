@import "../../../styles/variables";

.growl-component {
  top: 20px;
  right: 20px;
  position: fixed;
  max-width: 30%;
  z-index: 1001;

  .growl-message {
    color: white;
    background-color: $red;
    width: fit-content;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;

    &.success {
      background-color: $green;
    }
  }
}

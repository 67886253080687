@import "../../../../../../styles/variables";

.property-supply-edit-component {
  .form-group {
    width: 100%;
  }
  .foreign-holder {
    width: auto;
    margin: 1.5rem;
    display: flex;

    &:hover {
      cursor: pointer;
    }
    span {
      margin: auto;
      margin-left: 1rem;
    }
  }
  .copy-supply-information {
    margin: auto;
    text-transform: uppercase;
  }
  .save {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 1;
  }
}

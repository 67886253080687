@import "../../../../styles/variables";

.agency-detail {
    background-color: $background-gray;
    min-height: 100%;

    &__title {
        margin: 0;
        height: 3.75rem;
        align-items: center;
        display: flex;
        padding: 1rem 1.5rem;
        border: solid 1px $light-gray;
        background: white;
        font-size: 1.625rem;
        font-weight: 500;
    }

    &__edit {
        background: white;
        border: solid 1px #eeedec;
        padding: 2rem 1.5rem;
        margin: 1rem 0.875rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-img {
            max-width: 100px;
        }

        svg {
            cursor: pointer;
        }
    }

    .no-agencies {
        text-align: center;
        padding-top: 20px;
    }
}

@import "../../../../styles/variables";

.agencies-list-component {
	.form-group__item-addon {
		height: 3.75rem;
		position: sticky;
		top: 0;

		button {
			width: 56px;
		}
	}

	.property-item {
		display: flex;
		align-items: center;
		padding: 12px 20px;
		border: solid 1px $light-gray;
		border-bottom: none;
		cursor: pointer;

		&:hover {
			background-color: $light-blue;
		}

		.property-info {
			color: $gray;
			width: calc(100% - 26px);
			padding-left: 10px;
		}

		&.active {
			background-color: $background-gray;
			border-right: none;
			border-left: solid 6px $dark-blue;
			padding-left: 14px;

			.property-info {
				color: $dark-blue;
			}
		}

		&:last-child {
			border-bottom: solid 1px $light-gray;
		}
	}

	.see-more {
		background-color: $light-blue;
		color: $dark-gray;
		font-weight: bold;
		padding: 16px;
		text-align: center;
		cursor: pointer;
		border: solid 1px $light-gray;
	}
}

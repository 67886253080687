@import '~design-system/src/scss/00-core/themes/selectra';
@import '~design-system/src/scss/00-core/variables';

.modal-confirmation-component {
	h5 {
		margin-bottom: 16px;
		text-align: center;
	}

	.buttons {
		text-align: center;

		button {
			margin: 0 5px !important;
		}
	}
}

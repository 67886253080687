.recover-password {
    background-image: url('/backgrounds/recover-password.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    &__logo {
        text-align: center;
        margin: 2.5rem 0;
    }

    &__logo img {
        max-width: 179px;
    }
    
    &__card {
        min-width: 474px;
        padding: 2.5rem 2.625rem;
    }
    
    &__card .card__title {
        text-align: center;
        font-size: 2rem;
    }

    &__card .form-group {
        margin-top: 2rem;
    }

    &__card .form-group__addon {
        background-color: #CAD8E6;
    }

    &__card--btn {
        margin-top: 1.5rem;
    }

    &__alert {
        min-width: 474px;
    }
}

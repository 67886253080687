@import "../../../../styles/variables.scss";

.agency-company-edit {
    border: solid 1px #eeedec;
    border-top: none;

    .form-group {
        margin-bottom: 1rem;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
            margin: 0;
            color: $dark-blue;
            width: 30%; 
        }

        label:first-child {
            width: 40%; 
        }
    }

    &__grp-btns {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1rem;
        border-top: solid 1px $light-gray;
        margin-top: 1rem;
    }

    small.form-group__msg {
        justify-content: flex-end;
    }
}

@import "../../../../styles/variables.scss";

.agency-company-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: solid 1px $light-gray;
    padding: 1rem;
    margin-top: 1rem;

    p {
        margin: 0;
        font-weight: 600;
    }

    &__info {
        border: solid 1px #eeedec;
        border-top: none;

        p {
            margin-top: 0;
            margin-bottom: .5rem;
            text-align: left;
        }

        &-item {
            margin-right: .5rem;
            color: $dark-blue;
        }
    }

    &__btn-edit {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        border-top: solid 1px $light-gray;
        text-align: right;
        margin-top: 1rem;

        .btn--danger {
            text-transform: uppercase;
        }
    }
}

.opened {
    & .icon {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    & .agency-company-component {
        border-bottom: none;
    }
}

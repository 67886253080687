@import '../../../../../../styles/variables';

.property-documents-component {
    padding: 1rem;

    &__title {
        margin-bottom: 1.4625rem;
        font-weight: 500;
        font-size: 1.375rem;
    }

    &__wrapper {
        background-color: white;
        border: solid 1px $light-gray;
        padding-top: .5rem;

        .no-documents {
            padding: 0.5rem 1rem;
            margin: 0;
            text-align: center;
        }

        &-item {
            padding: .5rem 1rem;
            display: flex;
            justify-content: space-between;

            p {
                margin: 0;
            }

            &__doc {
                display: flex;
                align-items: center;

                p {
                    margin: 0;
                }

                .delete_icon {
                    cursor: pointer;
                }
            }
        }

        &__btn {
            border-top: 1px solid $light-gray;
            padding: 0.5rem 1rem;
            text-align: right;
            margin-top: .5rem;

            button {
                text-transform: uppercase;
            }
        }
    }
}

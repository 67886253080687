@import "../../styles/variables";

.header-component {
	position: fixed;
	width: 100%;
	z-index: 3;

	.beta {
		position: relative;
		top: -8px;
		right: -4px;
		background-color: orange;
		border-radius: 12px;
		padding: 0 4px;
		color: #fff;
	}

	.top-header {
		background-color: $dark-blue;
		height: 75px;

		.container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			height: 100%;

			img {
				max-width: 150px;
			}

			.icons-container {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				&__name-user,
				&__country-user-name {
					margin: 0;
					color: white;
				}

				&__country-user-name {
					margin-left: 4px;
				}

				&__country-user {
					display: inline-flex;
    				margin-left: 40px;

					& img {
						width: 12px;
						height: 12px;
						position: relative;
						margin: 8% 0;
					}
				}

				.add-user {
					position: relative;

					.plus-icon {
						position: absolute;
						margin: 0;
						fill: $dark-blue;
						background-color: white;
						padding: 5px;
						border-radius: 50%;
						top: -10px;
						right: -15px;
						width: 1.2rem;
						height: 1.2rem;
					}
				}

				.notifications {
					position: relative;

					.not-read-amount {
						position: absolute;
						font-size: 12px;
						font-weight: bold;
						top: -10px;
						right: -15px;
						background-color: $red;
						color: white;
						border: solid 2px white;
						border-radius: 50%;
						padding: 0 5px;
					}
				}

				svg {
					cursor: pointer;
					margin-left: 40px;
				}
			}
		}
	}

	.router-header {
		background-color: $light-blue;
		height: 75px;

		.container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			height: 75px;

			.links-container {
				a {
					position: relative;
					margin-right: 10px;

					svg {
						margin-right: 5px;
					}

					&.active {
						color: white;
						border-color: $dark-blue;
						background-color: $dark-blue;

						svg {
							fill: white;
						}
						
						&:after {
							position: absolute;
							content: '';
							border-bottom: solid 4px $dark-blue;
							bottom: -17px;
							width: 100%;
						}
					}

					&:hover {
						color: white;
						border-color: $dark-blue;
						background-color: $dark-blue;
						
						svg {
							fill: white;
						}
					}
				}
			}

			button, a {
				color: $dark-blue;
				border-color: $dark-blue;
				background-color: white;

				&:hover {
					color: white;
					border-color: $dark-blue;
					background-color: $dark-blue;
				}
			}
		}
	}
}

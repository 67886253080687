@import '~design-system/src/scss/00-core/themes/selectra';
@import '~design-system/src/scss/00-core/variables';
@import '~breakpoint-sass';

.spinner-container {
  width: 100%;
  position: fixed;
  min-height: 100vh;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 13;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    max-width: 30%;
    max-height: 95%;
  }

  @include breakpoint(0 $bp-md - 1) {
    > img {
      max-width: 90%;
    }
  }
}

@import "../../../../styles/variables";

.property-detail-component {
	background-color: $background-gray;
	min-height: 100%;

	.no-properties {
		text-align: center;
		padding-top: 20px;
	}

	h4 {
		color: $dark-blue;
		margin-bottom: 1rem;
		font-weight: 500;
    	font-size: 1.375rem;
	}
}

@import '../../../styles/variables';

.checkbox {
  label {
    display: flex;
    position: relative;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;

    input {
      display: none;
    }

    .checkmark {
      cursor: pointer;
      display: block;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: #eee;
      margin-right: 5px;

      &:hover {
        background-color: #ccc;
      }
    }

    input:checked ~ .checkmark {
      background-color: $dark-blue;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 7px;
      bottom: 5px;
      width: 8px;
      height: 16px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
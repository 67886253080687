@import "../../../../../../styles/variables";

.property-conversation-component {
  padding: 16px;

  .icon-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    svg {
      fill: $dark-gray;
      margin-right: 10px;
    }
  }

  .conversation-container {
    height: 400px;
    background-color: #FDFCFC;
    border: solid 1px $gray;
    overflow-y: auto;

    .messages-container {
      height: calc(100% - 74px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;

      .message-container {
        .day {
          padding: 12px 20px;
          font-size: 1.2rem;
          color: $gray;
          border-bottom: solid 1px $light-gray;
        }

        .loading {
          justify-content: center;
          background-color: $blue;
          color: white;
        }

        .message {
          display: flex;
          align-items: center;
          padding: 10px;
          border-bottom: solid 1px $light-gray;

          .action-title {
            color: $dark-blue;
          }

          .action-subtitle {
            color: $gray;
            font-size: 0.9rem;

            > div {
              margin-top: 3px;
            }

            .field-change {
              padding: 2px 5px;
              background-color: $light-gray;
              border-radius: 3px;
              margin: 0 5px;
            }

            svg {
              fill: $gray;
            }
          }

          .message-info {
            margin-left: 10px;
            width: calc(90% - 48px);
          }

          p {
            margin: 0;
            font-size: 0.9rem;
          }

          .icon-container {
            position: relative;
            border-radius: 50%;
            width: 48px;
            height: 48px;
            background-color: $light-blue;
            display: flex;
            align-items: center;
            justify-content: center;

            .supply-type {
              fill: $dark-blue
            }

            .message-type {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: $red;
              bottom: -4px;
              right: -4px;
            }
          }

          &.comment {
            background-color: $light-blue;

            .icon-container {
              background-color: white;
            }

            &.sender {
              flex-direction: row-reverse;
              text-align: right;

              .message-info {
                margin-left: 0;
                margin-right: 10px;
              }
            }
          }

          &.action {
            justify-content: center;

            .message-info {
              width: 50%;
            }
          }
        }
      }
    }

    .add-comment {
      width: 100%;
      padding: 16px 40px;
      background-color: white;
      position: relative;

      input {
        height: 42px;
        border-radius: 50px;
        padding-right: 42px;
        padding-left: 20px;
      }

      button {
        position: absolute;
        border-radius: 50%;
        height: 42px;
        width: 42px;
        right: 40px;
      }
    }
  }
}

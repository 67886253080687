@import "../../../../../../styles/variables";

.property-supply-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: solid 1px $light-gray;
  padding: 10px;
  margin-top: 10px;

  > div {
    display: flex;
    align-items: center;
  }

  .icon-container {
    flex: 1;
    position: relative;

    span {
      width: 40px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 16px;
      background-color: $light-orange;

      svg {
        fill: $orange;
      }
    }

    .has-incidences {
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: $red;
      border-radius: 50%;
      top: 0;
      left: 28px;
    }

    .has-invoices {
      position: absolute;
      top: 0;
      left: -3px;
    }
  }

  .status {
    flex: 2;
    border-radius: 5px;
    background-color: $light-orange;

    svg {
      fill: $orange;
    }

    span {
      width: calc(100% - 34px);
      border-right: solid 1px $orange;
      padding: 5px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }

      &.not-allowed {
        width: 100%;
        border-right: none !important;
      }
    }

    div {
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .holder {
    flex: 1;
    justify-content: flex-end;

    svg {
      fill: $dark-gray;
      cursor: pointer;
    }

    .opened {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    button {
      padding: 5px 10px;
      border-radius: 2px;
      background-color: $light-gray;
      color: $dark-gray;
      border: none;
      font-weight: bold;
      font-size: 14px;
      outline: none;
    }
  }

  &.active, &.processing_holder_change {
    .icon-container span, .status {
      background-color: $light-green;

      svg {
        fill: $green;
      }
    }

    .status span {
      border-right: solid 1px $green;
    }
  }

  &.processing_unsubscription, &.unsubscribed {
    .icon-container span, .status {
      background-color: $light-red;

      svg {
        fill: $red;
      }
    }

    .status span {
      border-right: solid 1px $red;
    }
  }

  &.not_registered,
  &.not_apply,
  &.cancelled {
    background-color: $background-gray;

    .icon-container span {
      background-color: $light-gray;

      svg {
        fill: $dark-gray;
      }
    }

    .status {
      background-color: $light-gray;

      span {
        border-right: solid 1px $gray;
      }

      svg {
        fill: $gray;
      }
    }
  }
}

@import '~breakpoint-sass/stylesheets/breakpoint';
@import '~design-system/src/scss/00-core/themes/selectra';
@import '~design-system/src/scss/00-core/variables';
@import "variables";

.hidden {
  display: none !important;
}

@include breakpoint(0 $bp-md - 1) {
  .hidden-mobile {
    display: none;
  }
}

input {
  margin-top: 0 !important;
}

.react-datepicker-wrapper {
  width: 100%;
  z-index: 10;

  input {
    width: 100%;
    padding: 0.5rem 0.5rem;
    line-height: 1.5;
    color: #565e66;
    border: 1px solid #abbccc;
    border-radius: 0.125rem;
    outline: none;
  }
}

.react-datepicker-popper {
  z-index: 2;

  .react-datepicker__navigation {
    outline: none;
  }
}

.rc-tooltip {
  opacity: 1;
  background-color: transparent;
  z-index: 2;

  .rc-tooltip-inner {
    padding: 0;
    border: none;
    margin-top: 10px;
  }

  .rc-tooltip-arrow {
    margin-top: 10px;
    border-bottom-color: $light-gray;
  }

  .label-info {
    padding: 5px 10px;
    background-color: white;
    border-radius: 2px;
    color: $dark-gray;
    border: solid 1px $dark-gray;
    display: block;
  }
}

label {
  display: block;
  margin: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.margin-bottom {
  margin-bottom: 16px;
}

.margin-top {
  margin-top: 16px;
}

.no-margin {
  margin: 0;
}

.select__control {
  background-color: white;
  border-color: $light-gray;
  box-shadow: none !important;
  border-radius: 2px !important;
}

.select__control--is-focused {
  border-color: $dark-blue !important;
}

.select__menu {
  z-index: 2 !important;
  div {
    .select__option--is-selected {
      background-color: $dark-blue;
    }
  }
}

.react-tagsinput {
  border-radius: 0.125rem;
  border-color: #abbccc;

  &.react-tagsinput--focused {
    border-color: $dark-blue;
  }

  .react-tagsinput-tag {
    color: white;
    background-color: $dark-blue;
    border: none;

    a {
      color: white;
      text-decoration: none;
    }
  }
}

.form-group--has-danger {
  .select__control {
    border-color: $red;
  }
}

.bold {
  font-weight: bold;
}

.ql-editor {
  min-height: 100px;
}

textarea {
  min-height: 150px;
  resize: none;
}

.alerts {
  margin-bottom: 1rem;
  margin-top: 1.5625rem;
  font-size: .8125rem;

  &__success {
    background-color: #e9f6eb;
    border: 1px solid $green;
    color: $green;
  }

  &__danger {
    background-color: #fee9e9;
    border: 1px solid $red;
    color: $red;
  }
}

.clickable {
  cursor: pointer;
}

.header-padding {
  padding-top: 170px;
  margin-bottom: 16px;
}

.circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 1px $dark-blue;
  cursor: pointer;

  &.active {
    background-color: $dark-blue;
  }
}

.disabled {
  cursor: not-allowed;
}

.padding {
  padding: 16px;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-left {
  padding-left: 0;
}

button {
  cursor: pointer;
}

.relative {
  position: relative;
}

.scroll-list {
  overflow: hidden;
  overflow-y: auto;

  &.properties {
    max-height: 1000px;
    min-height: 700px;
  }

  &.agencies {
    max-height: 1000px;
    min-height: 700px;
  }
}

.padding-top {
  padding-top: 20px;
}

.padding-bottom {
  padding-bottom: 20px;
}

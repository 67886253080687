@import '../../styles/variables';

.forgot-password {
  background-image: url('/backgrounds/forgot-password.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;

  &__logo {
    text-align: center;
    margin: 2.5rem 0;
  }

  &__logo img {
    max-width: 179px;
  }

  &__card {
    min-width: 474px;
    padding: 2.5rem 2.625rem;
  }

  &__card .card__title {
    text-align: center;
    font-size: 2rem;
  }

  &__card .form-group {
    margin-top: 2rem;
  }

  &__card--btn {
    margin-top: .5rem;
  }

  &__alert {
    min-width: 474px;
  }

  .log-in-link {
    margin-top: 10px;
    display: block;
    text-align: center;
  }
}

@import "../../../styles/variables";

.modal-component {
	width: 100vw;
	height: 100vh;
	z-index: 4;
	position: fixed;
	background-color: rgba(0, 0, 0, .6);
	display: flex;
	align-items: center;
	justify-content: center;

	.modal-content {
		padding: 30px;
		border-radius: 2px;
		background-color: white;
		position: relative;
        max-height: 95vh;
        // overflow: auto;

		.close-icon {
			position: absolute;
			top: 5px;
			right: 5px;
			cursor: pointer;
		}

		min-width: 29.625rem;

		.title {
			font-size: 2rem;
			text-align: center;
			margin: .5rem 0 2rem;
		}

		.subtitle-alert {
			color: #f42223;
		}

		.btn {
			margin-top: 1.5rem;
		}

		.form-group__addon {
			background-color: #CAD8E6;

			svg {
				fill: $dark-gray;
			}
		}
	}

	.form-group {
        margin-top: 1rem;
    }
}

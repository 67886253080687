@import "../../../../styles/variables";

.notifications-component {
	background-color: white;
	width: 350px;
	max-height: 400px;
	overflow-y: scroll;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;

	p {
		margin: 0;
	}

	.notifications {
		border-bottom: solid 1px $light-gray;
		padding: 10px 20px;
		color: black;
		font-weight: bold;
	}

	.notification {
		padding: 10px 20px;
		border-bottom: solid 1px $light-gray;
		display: flex;
		align-items: center;

		.notification-info {
			color: $gray;
			display: inline-block;
			width: calc(100% - 26px);
			font-size: 0.9rem;
			cursor: pointer;

			.title {
				color: $dark-blue;
				font-weight: bold;
				font-size: 1.1rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.circle {
			border: solid 1px $dark-blue;
			background-color: $dark-blue;

			&.active {
				cursor: default;
				background-color: white;
			}
		}

		.header-component .top-header .container 
		.icons-container &-supply {
			display: flex;
    		align-items: center;
			svg {
				margin-left: 0;
			}
		}
	}

	.no-notifications {
		padding: 10px 20px;
	}
}

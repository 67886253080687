@import '../../styles/variables.scss';

.log-in {
    height: 100vh;
    background-size: cover;
    background-image: url('/backgrounds/log-in.png');
    background-repeat: no-repeat;

    &__logo {
        text-align: center;
        margin: 2.5rem 0
    }

    &__logo img {
        max-width: 179px;
    }

    &__card {
        width: 29.625rem;
        padding: 2.625rem;
    }

    &__title {
        text-align: center;
        color: #1369B6;
        padding-bottom: 2rem;
        font-size: 2rem;
    }

    & .form-group__label {
        margin-bottom: 10px;
    }

    & .form-group__label + [class*='form-group__item'] {
        margin-top: 0;
    }

    &__email-input {
        color: #6B7580;
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    &__password-input {
        color: #6B7580;
        font-size: 1rem;
        margin-bottom: 0.625rem;
    }

    &__password-input .form-group__addon {
        background-color: #CAD8E6;
    }

    &__password-input .form-group__addon:hover {
        cursor: pointer;
    }

    .icon--gray {
        fill: #3F4D51;
    }

    .signin-google__btn img {
        width: 24px;
        height: 24px;
        margin: 0 5px;
    }
}
@import "../../../../../../styles/variables";

.property-supply-invoices-component {
  .invoices {
    max-height: 300px;
    overflow-y: auto;
    margin: 0 0 3em 0;

    .invoice-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .delete_icon {
        cursor: pointer;
      }
    }

    .no-invoices {
      border: solid 1px $dark-blue;
      color: $dark-blue;
      background-color: $light-blue;
      text-align: center;
      padding: 8px;
    }
  }
}
